<template>
  <NuxtLoadingIndicator />
  <TheHeader :user="userData" />
  <!-- <pre>{{ scrollCookies }}</pre> -->
  <NuxtPage />
  <TheFooter />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCookie, useNuxtApp } from "#app";

const userData = ref();
const route = useRoute();
const router = useRouter();

const token = useCookie("token").value;
const getUserData = useCookie("user").value;
const scrollCookies = useCookie("scrollHistory", { maxAge: 3600 }); // 1 saat geçerlilik süresi

const MAX_HISTORY = 2;

// Scroll pozisyonunu kaydetme
const saveScrollPosition = async () => {
  try {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    const path = route.fullPath;

    let scrollHistory = scrollCookies.value || "[]";
    console.log("scrollHistory", scrollHistory);

    const updatedHistory = await scrollCookies.value;

    console.log("updatedHistory", await updatedHistory);

    updatedHistory.unshift({ path, position: scrollTop });

    if (updatedHistory.length > MAX_HISTORY) {
      updatedHistory.splice(MAX_HISTORY); // Dizinin başından fazla olanları kes
    }

    scrollCookies.value = updatedHistory;
  } catch (error) {
    // console.error("Scroll position save error:", error);
  }
};

// Scroll pozisyonunu yükleme
const loadScrollPosition = () => {
  try {
    const path = route.fullPath;
    console.log("route", route);
    console.log("path", path);
    const scrollHistory = scrollCookies.value || "[]";
    console.log("scrollHistory", scrollHistory);
    const savedPosition = scrollHistory.filter(
      (entry) => entry.path == path
    )[0];

    console.log("savedPosition", savedPosition);
    console.log("savedPosition.position ", savedPosition.position);

    if (savedPosition) {
      // window.scrollTo({ top: savedPosition.position, behavior: "smooth" });
      document.body.scroll({
        top: savedPosition.position,
        behavior: "smooth",
      });
    } else {
      document.body.scroll({ top: 0, behavior: "smooth" });
    }
  } catch (error) {
    // alert("Scroll position load error:", error);
    document.body.scroll({ top: 0, behavior: "smooth" });
  }
};

// Sayfa geçişinden önce scroll pozisyonunu kaydetme
router.beforeEach((to, from, next) => {
  saveScrollPosition();
  next();
});

// Sayfa yüklendiğinde scroll pozisyonunu yükleme
onMounted(() => {
  userData.value = token && getUserData ? getUserData : null;
});

const nuxtApp = useNuxtApp();
const loading = ref(true);

nuxtApp.hook("page:start", () => {
  loading.value = true;
});

nuxtApp.hook("page:finish", () => {
  loading.value = false;
});

watch(
  () => route.query,
  (newValue) => {
    loading.value = true;
    if (process.client) {
      loadScrollPosition();
    }

    if (newValue) {
      setTimeout(() => {
        loading.value = false;
      }, 500);
    }

    if (route.query.login === "success" || route.query.login === "logout") {
      if (process.client) {
        // console.log("test");
        let token = useCookie("token").value;
        let getUserData = useCookie("user").value;

        if (token && getUserData) {
          userData.value = getUserData;
          // console.log("set user", userData.value);
        } else {
          userData.value = null;
        }
        console.log("user", userData.value);
      }
    }
  },
  { immediate: true }
);
</script>

<style>
.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#00a0 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
