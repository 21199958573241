<template>
  <UPopover overlay v-model:open="popoverActive">
    <div class="search" @click="changePopoverActive">
      <input
        type="text"
        class="form-control"
        v-model="keyword"
        placeholder="Alışverişin için en uygun seçimi bul..."
        @click="focusInput"
        autocomplete="off"
      />
      <button class="mtbtn">
        <span class="icon lnr lnr-magnifier"></span>
      </button>
    </div>

    <template #panel>
      <div class="search">
        <span @click="searchModalClose" class="close mobile-visible">
          <span class="icon lnr lnr-arrow-left"></span>
        </span>
        <input
          type="text"
          class="form-control pl40"
          v-model="keyword"
          clear
          placeholder="Alışverişin için en uygun seçimi bul..."
          id="search"
          @keydown.enter="handleEnter"
          autocomplete="off"
        />
        <button @click="handleEnter">
          <span class="icon lnr lnr-magnifier"></span>
        </button>
      </div>
      <div class="search-list">
        <span class="warning" v-if="!errorControl && keyword.length < 3">
          Aramaya başlamak için en az 2 karakter yazmalısınız
        </span>
        <span class="warning" v-if="errorControl">
          {{ errorMessage }}
        </span>

        <div
          class="column"
          v-if="searchList.items && searchList.items.length > 0"
        >
          <span class="title">Önerilen Ürünler</span>
          <ul>
            <li v-for="item in searchList.items">
              <NuxtLink :to="{ path: `/${item.category.slug}/${item.slug}` }">
                <div class="img">
                  <img
                    v-if="
                      item.images.filter((img) => img.url.includes('/sm/'))
                        .length > 0
                    "
                    :src="
                      'https://uygunsecim.b-cdn.net/' +
                      item.images.filter((img) => img.url.includes('/sm/'))[0]
                        .url
                    "
                    alt=""
                  />
                </div>
                {{ item.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div
          class="column"
          v-if="
            searchList.filter &&
            searchList.filter['categorys.q'] &&
            searchList.filter['categorys.q'].length > 0
          "
        >
          <span class="title">Önerilen Kategoriler</span>
          <ul>
            <li
              v-for="item in searchList.filter['categorys.q'].slice(0, 5)"
              class="arrow"
            >
              <NuxtLink
                :to="{
                  path: `/${item.slug}-c${item.id}`,
                  query: { q: encodeURIComponent(keyword) },
                }"
              >
                <!-- query: { c: item.id, q: ' ', searchTitle: item.name }, -->

                {{ item.name }}
                <UIcon name="icon i-heroicons-arrow-up-right" />
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div
          class="column brands"
          v-if="
            searchList.filter &&
            searchList.filter['brand.q'] &&
            searchList.filter['brand.q'].length > 0
          "
        >
          <span class="title">Önerilen Markalar</span>
          <ul>
            <li v-for="item in searchList.filter['brand.q'].slice(0, 6)">
              <NuxtLink
                :to="{
                  path: '/search',
                  query: {
                    b: item.id,
                    q: encodeURIComponent(keyword),
                    searchTitle: item.name,
                  },
                }"
              >
                {{ item.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
import { ref, watchEffect, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

// console.log("route = ", route);

const popoverActive = ref(false);
const keyword = ref("");
const searchList = ref();

const errorControl = ref(false);
const errorMessage = ref("Üzgünüz, arama sonucu bulunamadı.");

const changePopoverActive = () => {
  setTimeout(() => {
    popoverActive.value = true;

    if (process.client) {
      // .search-bar sınıfına sahip elementi seçme
      const searchBar = document.querySelector(".search-bar");

      if (searchBar) {
        // Eğer .search-bar sınıfına sahip bir element varsa işlemleri yap
        // .search-bar altındaki .overflow-hidden sınıfına sahip alt elementi seçme
        const overflowHiddenElement =
          searchBar.querySelector(".overflow-hidden");

        if (overflowHiddenElement) {
          // Eğer .overflow-hidden sınıfına sahip bir element varsa işlemleri yap
          // Seçilen elemente stil ekleyin
          overflowHiddenElement.classList.add("opacity-1");
        }
      }
    }
  }, 300);
};

const handleEnter = async () => {
  // console.log("Enter tuşuna basıldı!");

  router.push({
    path: "/search",
    query: {
      q: encodeURIComponent(keyword.value)
        ? encodeURIComponent(keyword.value)
        : "",
    },
  });
};

const searchModalClose = () => {
  popoverActive.value = false;
};

const focusInput = () => {
  setTimeout(() => {
    if (process.client) {
      const focusInputSelect = document.querySelector('input[id="search"]');
      if (focusInputSelect) {
        focusInputSelect.focus();
      }
    }
  }, 150);
};

watchEffect(async () => {
  if (keyword.value.length > 3) {
    try {
      const cleanedKeyword = keyword.value.replace(/\s+/g, "+"); // Boşlukları kaldır

      const { data } = await useAsyncData("categoriesList", () =>
        fetchSearchQuery({
          q: cleanedKeyword, //keyword.value,
          pageType: "filter",
          limit: 5,
        })
      );

      const result = data.value;

      errorControl.value = false;
      searchList.value = result;
      // console.log(searchList.value); // Sonucu konsola yazdır
    } catch (error) {
      errorControl.value = true;
      //   console.error("Hata oluştu:", error);
    }
    if (searchList.value.items.length == 0) {
      searchList.value = "";
      errorControl.value = true;
    }
  } else {
    searchList.value = "";
  }
});

// Route değişikliklerini izleme
watch(
  () => route.path,
  (newValue, oldValue) => {
    // console.log("new value:", newValue);
    popoverActive.value = false;
    keyword.value = "";
  }
);

watch(
  () => route.query,
  (newValue, oldValue) => {
    // console.log("new query:", newValue);
    popoverActive.value = false;
    keyword.value = "";
  }
);
</script>
