<script setup lang="ts">
import { ref } from "vue";
import { useWindowSize } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import { useCookie } from "#app";

const router = useRouter();
const route = useRoute();

const props = defineProps<{
  user: any;
}>();

const profilMenuDropdown = ref(false);

const profileMemuItems = [
  [
    {
      label: "Favorilerim",
      icon: "icon lnr lnr-star",
      click: () => {
        // console.log("Edit");
        router.push("/hesabim/favorilerim");
      },
    },
  ],

  [
    {
      icon: "icon lnr lnr-user",
      label: "Profil Bilgilerim",
      click: () => {
        router.push("/hesabim/bilgilerim");
      },
    },
  ],

  [
    {
      icon: "icon lnr lnr-lock",
      label: "Çıkış Yap",
      click: () => {
        logout();
      },
    },
  ],
];

const isLoggedIn = ref(false);

const user = computed(() => props.user);
// console.log("header user ", user);

const { width } = useWindowSize();

const count = ref(0);

// Fonksiyon tanımı: İsimleri İT olarak kısaltma
const getInitials = (name) => {
  const words = name.split(" ");
  const initials = words.map((word) => {
    return word.charAt(0).toUpperCase();
  });
  return initials.join("");
};

const popoverActive = ref(false);

// Route değişikliklerini izleme
watch(
  () => route.query,
  (newValue, oldValue) => {
    // Örnek olarak yeni sort parametresi eklenirse işlemler buraya yazılabilir
    if (newValue && oldValue != undefined) {
      popoverActive.value = false;
    }
  },
  { immediate: true } // İlk render işlemi sırasında değişiklikleri takip et
);

// Kategori listesini almak için async fonksiyon
// const categoriesList = ref<Category[]>([]);

// const fetchCategoryList = async () => {
//   try {
//     categoriesList.value = await fetchHeaderCategoryList();
//   } catch (error) {
//     console.error("Kategori listesi alınamadı", error);
//   }
// };

const { data } = await useAsyncData("categoriesList", fetchHeaderCategoryList);

const categoriesList = ref<any>(data.value);

// Component yüklendiğinde kategori listesini al
// fetchCategoryList();

interface Category {
  id: number;
  name: string;
  children: SubCategory[];
}

interface SubCategory {
  id: number;
  name: string;
  slug: string;
}

// Çıkış yap fonksiyonu
const logout = async () => {
  const cookie = useCookie("token");
  cookie.value = "";

  const cookieUser = useCookie("user");
  cookieUser.value = "";

  // await router.push("/?login=logout");
  await router.push({ query: { login: "logout" } });
};
</script>

<template>
  <header class="fixed-top">
    <div class="container">
      <div class="d-flex m-f">
        <div class="left">
          <div class="logo">
            <NuxtLink to="/">
              <img src="/assets/img/logo.svg" />
            </NuxtLink>
          </div>
          <div class="categories">
            <button class="select" @click="popoverActive = !popoverActive">
              <UIcon
                class="icon"
                name="i-heroicons-bars-3-bottom-left"
                dynamic
              />
              <span>Kategoriler</span>
            </button>

            <UPopover
              :mode="width >= 1000 ? 'hover' : 'click'"
              overlay
              :openDelay="0"
              v-model:open="popoverActive"
            >
              <span color="white" label="" style="display: none" />

              <template #panel :transition="false">
                <CategoryHeaderCategoriesMenu
                  :categories="categoriesList"
                  v-if="width >= 1000"
                />
                <CategoryMobileHeaderCategoriesMenu
                  @modalclose="popoverActive = false"
                  :categories="categoriesList"
                  v-else
                />
              </template>
            </UPopover>
          </div>
        </div>
        <div class="center search-bar">
          <GeneralSearchBar />
        </div>
        <div class="right">
          <div class="header-actions">
            <div class="dnone center search-bar extra" v-if="width <= 1000">
              <GeneralSearchBar />
            </div>

            <a href="#" class="extra">
              <span class="icon lnr lnr-heart"></span>
              <span class="count">2</span>
            </a>
            
            <div
              v-if="user && user.name"
              class="user-block profile-menu center"
            >
              <div
                class="left"
                @click="profilMenuDropdown = !profilMenuDropdown"
              >
                <div class="avatar">
                  <!-- <span class="icon lnr lnr-user"></span> -->
                  <!-- <span class="icon lnr lnr-user"></span> -->
                  {{ getInitials(user.name) }}
                  <!-- <span class="count">1</span> -->
                  <!-- <UKbd :value="getInitials(user.name)" /> -->
                </div>
              </div>
              <div
                class="right gap0"
                style="cursor: pointer"
                @click="profilMenuDropdown = !profilMenuDropdown"
              >
                <span class="name">{{ user.name }}</span>
                <!-- <span class="logout-btn" @click="logout">Çıkış Yap</span> -->
              </div>

              <UDropdown
                mode="click"
                :items="profileMemuItems"
                :popper="{ placement: 'bottom-end' }"
                v-model:open="profilMenuDropdown"
              >
                <span color="white" label="" style="display: none" />
              </UDropdown>
            </div>

            <div v-if="user == null" class="user-block">
              <div class="left">
                <NuxtLink to="/uye-girisi">
                  <span class="icon lnr lnr-user"></span>
                </NuxtLink>
              </div>
              <div class="right">
                <NuxtLink to="/uye-girisi">Giriş Yap</NuxtLink>
                <NuxtLink to="/uye-ol">Üye Ol</NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
/* Gerekirse CSS stilleri buraya eklenebilir */
</style>
