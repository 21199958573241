<template>
  <!-- Categories Menu -->
  <div class="categoriesList">
    <div class="container categories-menu-container">
      <div class="row">
        <!-- Parent Category -->
        <div class="col col-4 parent-category">
          <div class="base-menu scroll">
            <ul>
              <li v-for="category in categories">
                <NuxtLink
                  :to="`/${category.slug}-c${category.id}`"
                  :key="category.id"
                  @mouseover="showSubcategories(category)"
                >
                  <!-- <img class="ctimg" :src="'https://uygunsecim.b-cdn.net/categories/'+category.slug+'.jpg'"> -->
                  {{ category.name }} <i class="icon lnr lnr-chevron-right"></i>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
        <!-- Sub Category 1 -->
        <div class="col col-4">
          <div
            class="sub-menu scroll"
            v-if="activeCategory && activeCategory.nested_children.length > 0"
          >
            <ul>
              <li v-for="subcategory in activeCategory.nested_children">
                <!-- <div class="img">
                  <img
                    :src="
                      'https://uygunsecim.b-cdn.net/categories/' +
                      subcategory.slug +
                      '.jpg'
                    "
                  />
                </div> -->
                <NuxtLink
                  :to="`/${subcategory.slug}-c${subcategory.id}`"
                  :key="subcategory.id"
                  @mouseover="showSubChildCategories(subcategory)"
                  >{{ subcategory.name }}
                  <i class="icon lnr lnr-chevron-right"></i>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
        <!-- Sub Category 2 -->
        <div
          class="col col-4 sub-category-2"
          v-if="
            activeSubCategory &&
            activeSubCategory.nested_children &&
            activeSubCategory.nested_children.length > 0
          "
        >
          <div class="sub-child-menu scroll">
            <ul>
              <li
                v-for="subchild in activeSubCategory.nested_children"
                :key="subchild.id"
              >
                <NuxtLink :to="`/${subchild.slug}-c${subchild.id}`">{{
                  subchild.name
                }}</NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

defineProps<{
  categories: [];
}>();

interface Category {
  id: number;
  name: string;
  nested_children: SubCategory[];
}

interface SubCategory {
  id: number;
  name: string;
  slug: string;
}

const activeCategory = ref<Category | null | any>(null);
const activeSubCategory = ref<SubCategory | null>(null);

const showSubcategories = async (category: Category) => {
  if (activeCategory.value && activeCategory.value.id === category.id) {
    return;
  }

  // Yeni kategoriye geçiş
  activeCategory.value = category;
  // mouseOverWithDelay(category, 0).then((result) => {
  // });

  // Alt kategoriyi sıfırla
  activeSubCategory.value = null;
};

const showSubChildCategories = (subcategory: SubCategory) => {
  activeSubCategory.value = subcategory;
};

const mouseOverWithDelay = (category: any, delay: any) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(category);
    }, delay * 0);
  });
};
</script>
