<template>
  <div class="categoriesList">
    <div class="container categories-menu-container">
      <div class="row">
        <!-- Parent Category -->
        <div class="col col-12 parent-category" v-if="!activeCategory">
          <span class="prev" @click="modalClose">
            <UIcon class="icon" name="i-heroicons-x-mark" dynamic />
            Kategoriler
          </span>

          <div class="base-menu scroll">
            <ul>
              <li
                v-for="category in categories"
                :key="category.id"
                @click.prevent="showSubcategories(category)"
              >
                <NuxtLink to="">
                  {{ category.name }} <i class="icon lnr lnr-chevron-right"></i>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>

        <!-- Dynamic Subcategories -->
        <div class="col col-12">
          <div class="sub-menu scroll" v-if="activeCategory">
            <span class="prev" @click="goBack">
              <i class="icon lnr lnr-arrow-left"></i>
              <span class="categoriesName">{{ activeCategory.name }}</span>
            </span>

            <ul>
              <li
                v-for="subcategory in activeCategory.nested_children"
                :key="subcategory.id"
                @click.prevent="showSubcategories(subcategory)"
              >
                <NuxtLink to="">
                  <!-- <div class="img">
                    <img
                      :src="`https://uygunsecim.b-cdn.net/categories/${subcategory.slug}.jpg`"
                    />
                  </div> -->
                  <span>{{ subcategory.name }}</span>
                  <i class="icon lnr lnr-chevron-right"></i>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
defineProps<{
  categories: [];
}>();

interface Category {
  id: number;
  name: string;
  slug: string;
  nested_children: SubCategory[];
}

interface SubCategory {
  id: number;
  name: string;
  slug: string;
  nested_children: SubCategory[];
}

const activeCategory = ref<Category | null>(null);
const categoryStack = ref<Category[]>([]);

const showSubcategories = (category: Category) => {
  // Eğer alt kategoriler yoksa, doğrudan ilgili kategoriye yönlendir
  if (category.nested_children.length === 0) {
    router.push(`/${category.slug}-c${category.id}`);
    return;
  }

  if (activeCategory.value && activeCategory.value.id === category.id) {
    return;
  }

  // Kategori yığınını güncelle
  if (activeCategory.value) {
    categoryStack.value.push(activeCategory.value);
  }

  // Yeni kategoriye geçiş
  activeCategory.value = category;
};

const goBack = () => {
  if (categoryStack.value.length > 0) {
    activeCategory.value = categoryStack.value.pop() || null;
  } else {
    activeCategory.value = null;
  }
};

const emit = defineEmits(["modalclose"]);

const modalClose = () => {
  emit("modalclose", true);
};
</script>
